<template>
    <v-navigation-drawer
        app
        bottom
        clipped
        hide-overlay
        right
        style="height: 100%;"
        v-model="stockDrawer"
        width="300">
        <v-toolbar dense class="primary" dark>
            <v-toolbar-title>
                Stock: {{ currentOrderItem != null ? currentOrderItem.product.productName : '' }}</v-toolbar-title>
            <v-spacer />
            <BT-Increment-Quantity
                v-if="currentOrderItem != null"
                :measurements="measurements"
                :productID="productID"
                v-model.number="currentOrderItem.quantity" />
        </v-toolbar>
        <BT-Blade-List
            :canClose="false"
            :canRefresh="false"
            :canSelect="false"
            flexColumn
            flexWidth="100%"
            hideBackButton
            :items="availableConsignmentEntryItems"
            listItemClass="mx-0 px-0"
            showList
            useListItems>
            <template v-slot:toolbar>
                <v-btn
                    @click="stockDrawer = false"
                    class="primary lighten-2 mx-1"
                    text
                    width="45%">Cancel</v-btn>
                <v-btn
                    @click="applyAndClose"
                    class="primary lighten-2 mx-1"
                    text
                    width="45%">Apply</v-btn>
            </template>
            <template v-slot="{ item }">
                <!-- <v-list-item-avatar>
                    <v-btn @click.stop="useBatch(item)" text class="success--text"><v-icon>mdi-check</v-icon></v-btn>
                </v-list-item-avatar> -->
                <v-list-item-avatar>
                    <v-img :src="productLogoURL(item.productID)">
                    <template v-slot:placeholder>
                        <v-icon class="primary--text">mdi-cube-outline</v-icon>
                    </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ item.batch != null ? item.batch.batchcode : 'No Batch' }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Available: {{ item.available | toDisplayNumber }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <BT-Increment-Quantity-Edit
                        :measurements="measurements"
                        :productID="item.productID"
                        v-model="item.quantity" />
                </v-list-item-action>
            </template>
        </BT-Blade-List>
        <v-overlay :value="loadingMsg != null" class="text-center">
            <v-progress-circular indeterminate size="64" />
            <div>{{ loadingMsg }}</div>
        </v-overlay>
    </v-navigation-drawer>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Stock-Assign-Sidebar',
    components: {
        BTIncrementQuantity: () => import('~components/BT-Increment-Quantity.vue'),
        BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue')
    },
    data: function() {
        return {
            availableConsignmentEntryItems: [],
            currentOrderItem: null,
            loadingMsg: null,
            stockDrawer: false,
        }
    },
    watch: {
        showToggle() {
            this.stockDrawer = true;
        },
        stockDrawer(val) {
            if (val === true) {
                this.refresh();
            }
        }
    },
    props: {
        allConsignmentEntryItems: {
            type: Array,
            default: null
        },
        allConsignmentOrderItems: {
            type: Array,
            default: null
        },
        consignment: {
            type: Object,
            default: null
        },
        filteredConsignmentEntryItems: {
            type: Array,
            default: null
        },
        filteredConsignmentOrderItems: {
            type: Array,
            default: null
        },
        measurements: {
            type: Array,
            default: null
        },
        productID: {
            type: String,
            default: null
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Assign'
        },
        updateConsignment: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        applyAndClose() {
            if (this.updateConsignment) {
                //still to do sometime
            }
            else {
                var newCEntries = this.availableConsignmentEntryItems.filter(y => y.quantity > 0);
                if (this.isLengthyArray(newCEntries)) {
                    newCEntries.forEach(cEntry => {
                        this.allConsignmentEntryItems.push({
                            id: null,
                            batch: cEntry.batch,
                            batchID: cEntry.batchID,
                            consignmentID: this.consignment.id,
                            originalCounted: cEntry.quantity,
                            originalQuantity: cEntry.quantity,
                            product: cEntry.product,
                            productID: cEntry.productID,
                            quantity: cEntry.quantity,
                            quantityCounted: cEntry.quantity,
                            quantityDelivered: cEntry.quantity,
                            quantityPicked: cEntry.quantity
                        });

                        var filteredItem = {
                            id: null,
                            productID: cEntry.productID,
                            product: cEntry.product,
                            batchID: cEntry.batchID,
                            batch: cEntry.batch,
                            originalDelivered: cEntry.quantity,
                            quantity: cEntry.quantity,
                            quantityDelivered: cEntry.quantity,
                            isChanged: true
                        };

                        this.filteredConsignmentEntryItems.push(filteredItem);

                        this.currentOrderItem.consignmentEntryItems.push(filteredItem);
                        this.currentOrderItem.totalDelivered = this.currentOrderItem.consignmentEntryItems.sum(x => x.quantityDelivered);
                    })
                }
            }

            this.stockDrawer = false;
        },
        async refresh() {
            if (this.consignment != null && this.productID != null) {
                var filteredOItem = this.filteredConsignmentOrderItems.find(x => x.productID == this.productID);
                this.currentOrderItem = filteredOItem;

                var stockEntriesRes = await this.$BlitzIt.store.getAll('stock-items', { 
                    filterBy: 'Has Available Stock', 
                    locationID: this.consignment.departureLocationID, 
                    productID: this.productID }, true, null);

                this.availableConsignmentEntryItems = this.filteredConsignmentEntryItems.filter(y => y.productID == this.productID).map(x => Object.assign({}, { ...x, available: 0 }));

                stockEntriesRes.forEach(entry => {
                    var existing = this.availableConsignmentEntryItems.find(y => y.productID == entry.productID && y.batchID == entry.batchID);
                    if (existing == null) {
                        existing = {
                            id: null,
                            productID: entry.productID,
                            product: entry.product,
                            batchID: entry.batchID,
                            batch: entry.batch,
                            quantity: 0,
                            quantityPicked: 0,
                            available: entry.available
                        }

                        this.availableConsignmentEntryItems.push(existing);
                    }
                    else {
                        existing.available += entry.available;
                    }
                })

                this.availableConsignmentEntryItems = this.availableConsignmentEntryItems.filter(y => !this.filteredConsignmentEntryItems.some(e => e.productID == y.productID && e.batchID == y.batchID)).sort(firstBy(x => x.batch != null ? x.batch.dom : 0));
            }
        },
        // useBatch(cEntry) {
        //     if (this.currentOrderItem != null) {
        //         var needs = this.currentOrderItem.quantity - this.currentOrderItem.
        //     }
        // }
    }
}
</script>